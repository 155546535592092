import React, { useEffect, useState } from 'react';
import ReactDom from 'react-dom';
import Home from '~pages/Home';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import CreateGamePage from '~pages/CreateGame';
import JoinGamePage from '~pages/JoinGame';
import 'antd/dist/antd.css';
import WrapperWithSidebar from '~components/layout/WrapperWithSidebar';
import CommunicationPane from '~components/CommunicationPane';
import { HOME, JOIN_GAME, CREATE_GAME, GAME_OVER } from '~routing';
import '~services/message';
import useGameConnection from '~hooks/useGameConnection';
import GameOver from '~pages/GameOver';

const App = () => {
  const [wasConnected, setWasConnected] = useState(false);
  const { connected, gameId } = useGameConnection();

  useEffect(() => {
    if (connected) {
      setWasConnected(true);
    }
  }, [connected]);

  const sideBar =
    connected || wasConnected ? (
      <CommunicationPane connected={connected} />
    ) : undefined;
  return (
    <Router>
      <WrapperWithSidebar sideBar={sideBar}>
        <Switch>
          <Route path={CREATE_GAME} render={() => <CreateGamePage />} />
          <Route path={JOIN_GAME} render={() => <JoinGamePage />} />
          <Route path={GAME_OVER} render={() => <GameOver />} />
          <Route
            path={HOME}
            render={() =>
              connected || gameId ? <Home /> : <Redirect to={CREATE_GAME} />
            }
          />
        </Switch>
      </WrapperWithSidebar>
    </Router>
  );
};

ReactDom.render(<App />, document.getElementById('app'));
