import React, { useEffect, useState } from 'react';
import TrophyTwoTone from '@ant-design/icons/TrophyTwoTone';
import FrownTwoTone from '@ant-design/icons/FrownTwoTone';
import bemClassNames from '@enrise/bemclassnames';
import './GameOver.scss';
import useGameScore from '~hooks/useGameScore';
import { useUsersState, getCurrentUser } from '~state/users';
import Layout from 'antd/lib/layout';
import PageHeader from 'antd/lib/page-header';
import Result from 'antd/lib/result';

const { Content } = Layout;

const cn = bemClassNames('GameOver');

const GameOver = () => {
  const { winners } = useGameScore();
  const { currentUserId } = useUsersState();
  const currentUser = getCurrentUser();
  const [won, setWon] = useState(false);

  useEffect(() => {
    if (!currentUserId) {
      return;
    }
    if (winners.includes(currentUserId)) {
      setWon(true);
    }
  }, [winners, currentUserId]);

  return (
    <>
      <PageHeader title="Game finished" ghost={false} />
      <Content className={cn()}>
        <div className={cn('content')}>
          {won && (
            <Result
              icon={<TrophyTwoTone twoToneColor={currentUser?.color} />}
              title={
                winners.length > 1
                  ? 'It is a tie, congratulations!'
                  : 'You won, congratulations!'
              }
            />
          )}
          {!won && (
            <Result
              icon={<FrownTwoTone twoToneColor={currentUser?.color} />}
              title="You lost..."
            />
          )}
        </div>
      </Content>
    </>
  );
};

export default GameOver;
