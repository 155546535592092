import { useGlobalState, setGlobalState, getGlobalState } from '~state';

export const useUsersState = () => {
  const [usersState, setUsersState] = useGlobalState('users');
  const { users, currentUserId } = usersState;

  const addUser = (userId: UserIdType, user: UserType) =>
    setUsersState(({ currentUserId, users }) => ({
      currentUserId,
      users: users.set(userId, user),
    }));

  const setCurrentUser = (userId: UserIdType) =>
    setUsersState(({ currentUserId, users }) => {
      if (!users.has(userId)) {
        return { currentUserId, users };
      }
      return { currentUserId: userId, users };
    });

  const currentUser = (currentUserId && users.get(currentUserId)) ?? undefined;

  return { currentUserId, currentUser, users, addUser, setCurrentUser };
};

export const addUser = (userId: UserIdType, user: UserType) =>
  setGlobalState('users', ({ currentUserId, users }) => ({
    currentUserId,
    users: users.set(userId, user),
  }));

export const getUsers = () => getGlobalState('users').users;

export const getCurrentUser = () => {
  const { users, currentUserId } = getGlobalState('users');
  if (!currentUserId || !users.has(currentUserId)) {
    return null;
  }
  return users.get(currentUserId);
};

export const setCurrentUser = (userId: UserIdType) =>
  setGlobalState('users', ({ currentUserId, users }) => {
    if (!users.has(userId)) {
      return { currentUserId, users };
    }
    return { currentUserId: userId, users };
  });
