import React, { useEffect } from 'react';
import useGameConnection from '~hooks/useGameConnection';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import CopyOutlined from '@ant-design/icons/CopyOutlined';
import bemClassNames from '@enrise/bemclassnames';
import './Connection.scss';
import { sendAddUser, sendNewGame } from '~services/message';
import { useUsersState } from '~state/users';
import { getUrl, JOIN_GAME } from '~routing';
import { useGameState } from '~state/game';
import message from 'antd/lib/message';

const cn = bemClassNames('Connection');

type ConnectionProps = {
  onStepFinish: () => void;
};
const Connection = ({ onStepFinish: handleStepFinish }: ConnectionProps) => {
  const { gameId, gameCreated, newGame, connected } = useGameConnection();

  const { currentUserId, currentUser } = useUsersState();
  const [game] = useGameState();

  useEffect(() => {
    newGame();
  }, []);

  useEffect(() => {
    if (!connected) {
      return;
    }
    if (currentUserId && currentUser) {
      sendAddUser(currentUserId, currentUser);
    }
    sendNewGame(game);
    handleStepFinish();
  }, [connected, currentUserId]);

  if (!gameCreated || !gameId) {
    return (
      <div className={cn()}>
        <div className={cn('gameIdLoadingMessage')}>
          Creating a new connection ID
        </div>
        <div className={cn('gameIdLoadingIcon')}>
          <LoadingOutlined />
        </div>
      </div>
    );
  }

  const url = `${location.origin}${getUrl(JOIN_GAME, { gameId })}`;

  const copyGameIdToClipboard = async () => {
    await navigator.clipboard.writeText(gameId);
    message.success('Connection ID copied to clipboard');
  };

  const copyUrlToClipboard = async () => {
    await navigator.clipboard.writeText(url);
    message.success('Join url copied to clipboard');
  };

  return (
    <div className={cn()}>
      <div className={cn('gameIdMessage')}>Your game id:</div>
      <div className={cn('gameId')}>
        {gameId}{' '}
        <CopyOutlined className={cn('copy')} onClick={copyGameIdToClipboard} />
      </div>
      <div className={cn('shareLinkMessage')}>
        You can also share this direct link:
      </div>
      <div className={cn('joinUrl')}>
        {url}{' '}
        <CopyOutlined className={cn('copy')} onClick={copyUrlToClipboard} />
      </div>
    </div>
  );
};

export default Connection;
