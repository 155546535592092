import React, { useState, ReactNode, useEffect } from 'react';
import GameSettings from './GameSettings';
import Connection from './Connection';
import bemClassNames from '@enrise/bemclassnames';
import './CreateGame.scss';
import { useHistory } from 'react-router-dom';
import { HOME } from '~routing';
import Steps from 'antd/lib/steps';
import Result from 'antd/lib/result';

const { Step } = Steps;

const cn = bemClassNames('CreateGame');

const HostGame = () => {
  const [currentStep, setCurrentStep] = useState(0);

  const finishStep = () => setCurrentStep((currentId) => currentId + 1);
  const history = useHistory();

  useEffect(() => {
    if (currentStep === 2) {
      setTimeout(() => history.push(HOME), 1000);
    }
  }, [currentStep]);

  const steps: StepType[] = [
    {
      title: 'Game settings',
      content: <GameSettings onStepFinish={finishStep} />,
    },
    {
      title: 'Connect to a friend',
      content: <Connection onStepFinish={finishStep} />,
    },
    {
      title: 'Play',
      content: (
        <Result status="success" title="You are successfully connected" />
      ),
    },
  ];

  return (
    <div className={cn()}>
      <Steps current={currentStep}>
        {steps.map(({ title }, index) => (
          <Step title={title} key={index} />
        ))}
      </Steps>
      <div className={cn('content')}>{steps[currentStep].content}</div>
    </div>
  );
};

export default HostGame;

type StepType = {
  title: string;
  content: ReactNode;
};
