import React, { useEffect } from 'react';
import GameSettings from './GameSettings';
import useGameConnection from '~hooks/useGameConnection';
import './JoinGame.scss';
import bemClassNames from '@enrise/bemclassnames';
import { useUsersState } from '~state/users';
import { sendAddUser } from '~services/message';
import { useHistory } from 'react-router-dom';
import { HOME } from '~routing';
import Result from 'antd/lib/result';

const cn = bemClassNames('JoinGame');

const JoinGame = () => {
  const { currentUser, currentUserId } = useUsersState();
  const { connected } = useGameConnection();
  const history = useHistory();

  useEffect(() => {
    if (!connected) {
      return;
    }
    if (currentUserId && currentUser) {
      sendAddUser(currentUserId, currentUser);
      setTimeout(() => history.push(HOME), 1000);
    }
  }, [connected, currentUserId]);

  if (connected) {
    return (
      <div className={cn('content')}>
        <Result status="success" title="You are successfully connected" />
      </div>
    );
  }
  return (
    <div className={cn('content')}>
      <GameSettings></GameSettings>
    </div>
  );
};

export default JoinGame;
