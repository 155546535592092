import React from 'react';
import { useGameState } from '~state/game';
import { useUsersState } from '~state/users';
import useGameScore from '~hooks/useGameScore';
import UserOutlined from '@ant-design/icons/UserOutlined';
import './GameHeader.scss';
import bemClassNames from '@enrise/bemclassnames';
import PageHeader from 'antd/lib/page-header';
import Descriptions from 'antd/lib/descriptions';

const cn = bemClassNames('GameHeader');

const GameHeader = () => {
  const [{ currentPlayerId }] = useGameState();
  const { users } = useUsersState();
  const currentPlayer = users.get(currentPlayerId);
  const { currentUserId } = useUsersState();

  const { score } = useGameScore();

  const whosTurn =
    currentPlayerId === currentUserId
      ? 'It is your turn'
      : `It is ${currentPlayer?.name}'s turn`;

  const renderUserLabel = (userId: UserIdType, user: UserType) => (
    <span
      className={cn('user', { 'user--current': userId === currentPlayerId })}
    >
      <UserOutlined style={{ color: user.color }} />{' '}
      {currentUserId === userId ? 'You' : user.name}
    </span>
  );

  return (
    <PageHeader title={whosTurn} ghost={false} className={cn()}>
      <Descriptions size="small" column={2}>
        {[...users].map(([userId, user]) => (
          <Descriptions.Item key={userId} label={renderUserLabel(userId, user)}>
            {score.get(userId)}
          </Descriptions.Item>
        ))}
      </Descriptions>
    </PageHeader>
  );
};

export default GameHeader;
