import { DataType } from '~services/gameConnection';

const messageTypes = [
  'chat',
  'addUser',
  'newGame',
  'addMove',
  'setMoveConquered',
];

export const isMessage = (
  message: DataType | MessageType
): message is MessageType =>
  (message as MessageType).type !== undefined &&
  messageTypes.includes((message as MessageType).type);

export const isChatMessage = (
  message: MessageType
): message is ChatMessageType => message.type === 'chat';

export const isAddUserMessage = (
  message: MessageType
): message is AddUserMessageType => message.type === 'addUser';

export const isNewGameMessage = (
  message: MessageType
): message is NewGameMessageType => message.type === 'newGame';

export const isAddMoveMessage = (
  message: MessageType
): message is AddMoveMessageType => message.type === 'addMove';

export const isSetMoveConqueredMessage = (
  message: MessageType
): message is SetMoveConqueredType => message.type === 'setMoveConquered';

export type ChatMessageType = {
  type: 'chat';
  message: string;
  userId: UserIdType;
};

export type AddUserMessageType = {
  type: 'addUser';
  userId: UserIdType;
  user: UserType;
};

export type NewGameMessageType = {
  type: 'newGame';
  game: GameType;
};

export type AddMoveMessageType = {
  type: 'addMove';
  move: MoveType;
};

export type SetMoveConqueredType = {
  type: 'setMoveConquered';
  moveIndex: number;
};

export type MessageType =
  | ChatMessageType
  | AddUserMessageType
  | NewGameMessageType
  | AddMoveMessageType
  | SetMoveConqueredType;
