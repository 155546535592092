import React from 'react';
import { ChatMessageType } from '~services/message';
import bemClassNames from '@enrise/bemclassnames';
import './ChatMessage.scss';
import { useUsersState } from '~state/users';
import SendOutlined from '@ant-design/icons/SendOutlined';

const cn = bemClassNames('ChatMessage');

type ChatMessagePros = {
  message: ChatMessageType;
  sameSenderAsPreviousMessage: boolean;
};
const ChatMessage = ({
  message: { message, userId },
  sameSenderAsPreviousMessage,
}: ChatMessagePros) => {
  const { currentUserId, users } = useUsersState();

  const sender = userId === currentUserId ? 'You' : users.get(userId)?.name;
  const senderColor = users.get(userId)?.color;

  return (
    <li className={cn()}>
      {!sameSenderAsPreviousMessage && (
        <div className={cn('sender')}>
          <SendOutlined style={{ color: senderColor }} /> {sender}
        </div>
      )}
      <div className={cn('message')}>{message}</div>
    </li>
  );
};

export default ChatMessage;
