import React, { useRef, useEffect } from 'react';
import bemClassNames from '@enrise/bemclassnames';
import './Chat.scss';
import useChat from '~hooks/useChat';
import { Store } from 'antd/lib/form/interface';
import { useForm } from 'antd/lib/form/util';
import ChatMessage from './ChatMessage';
import Input from 'antd/lib/input';
import Form from 'antd/lib/form';
import Button from 'antd/lib/button';
import SendOutlined from '@ant-design/icons/SendOutlined';

const cn = bemClassNames('Chat');
const Chat = () => {
  const { chatMessages, sendChatMessage } = useChat();
  const [form] = useForm();
  const messageListRef = useRef<HTMLUListElement>(null);
  const inputRef = useRef<Input>(null);

  const scrollMessageListToBottom = () =>
    messageListRef.current?.scrollTo({
      top: messageListRef.current.scrollHeight,
    });

  const onFormSubmit = (values: Store) => {
    sendChatMessage(values.message);
    form.resetFields();
    inputRef.current?.focus();
  };

  useEffect(scrollMessageListToBottom, [chatMessages]);

  const isSameSenderAsPrevious = (index: number, userId: UserIdType) =>
    index === 0 ? false : chatMessages[index - 1].userId === userId;

  return (
    <div className={cn()}>
      <ul className={cn('messageList')} ref={messageListRef}>
        {chatMessages.map((chatMessage, index) => (
          <ChatMessage
            message={chatMessage}
            key={index}
            sameSenderAsPreviousMessage={isSameSenderAsPrevious(
              index,
              chatMessage.userId
            )}
          />
        ))}
      </ul>
      <Form
        onFinish={onFormSubmit}
        className={cn('sendMessageForm')}
        form={form}
      >
        <Form.Item name="message">
          <div className={cn('sendMessage')}>
            <Input className={cn('input')} ref={inputRef} />
            <Button
              htmlType="submit"
              type="primary"
              className={cn('sendButton')}
            >
              <SendOutlined />
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Chat;
