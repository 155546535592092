import { getMessages, sendMessage, addMessage } from '.';
import { MessageType, isChatMessage, ChatMessageType } from './types';

export const getChatMessages = () =>
  getMessages().filter((message: MessageType): message is ChatMessageType =>
    isChatMessage(message)
  );

export const sendChatMessage = (message: string, userId: UserIdType) => {
  const chatMessage: ChatMessageType = { type: 'chat', message, userId };
  sendMessage(chatMessage);
  addMessage(chatMessage);
};
