import { useGameState } from '~state/game';
import { useEffect, useState } from 'react';

const useGameScore = () => {
  const [game] = useGameState();
  const [score, setScore] = useState(new Map<string, number>());
  const [winners, setWinners] = useState<string[]>([]);
  const [gameOver, setGameOver] = useState(false);

  useEffect(() => {
    const score = new Map<string, number>();
    game.players.forEach((player, index) => {
      score.set(player, game.score[index]);
    });
    setScore(score);
  }, [game]);

  useEffect(() => {
    let highestScore = 0;
    let winners: UserIdType[] = [];
    score.forEach((score, userId) => {
      if (score < highestScore) {
        return;
      }
      if (score > highestScore) {
        highestScore = score;
        winners = [userId];
        return;
      }
      winners.push(userId);
    });
    setWinners(winners);
  }, [score]);

  useEffect(() => {
    const totalScore = [...score].reduce<number>(
      (total, [, userScore]) => (total += userScore),
      0
    );

    const gameOver = totalScore === game.width * game.height;
    setGameOver(gameOver);
  }, [score]);

  return { score, gameOver, winners };
};

export default useGameScore;
