import SimplePeer from 'simple-peer';
import createGameId from '~helpers/createGameId';
import createGameOffer from '~services/gameConnectionSignaling/createGameOffer';
import getGameOffer from '~services/gameConnectionSignaling/getGameOffer';
import removeGameConnection from '~services/gameConnectionSignaling/removeGameConnection';
import setGameAnwser from '~services/gameConnectionSignaling/setGameAnwser';
import watchGameConnection from '~services/gameConnectionSignaling/watchGameConnection';

let _simplePeer: SimplePeer.Instance;
let _gameId: GameIdType;
let _connected = false;
const gameCreatedCallbacks: GameCreatedCallback[] = [];
const setGameIdCallbacks: SetGameIdCallback[] = [];
const instanceCreatedCallbacks: InstanceCreatedCallback[] = [];

const isAnwser = (
  gameConnection: GameConnectionType
): gameConnection is GameConnectionAnswerType =>
  (gameConnection as GameConnectionAnswerType).answer !== undefined;

const setListeners = () => {
  if (!_simplePeer) {
    return;
  }
  _simplePeer.on('error', (error) => console.log('peerError', error));
  _simplePeer.on('signal', (signalData) => console.log('signal', signalData));
  _simplePeer.on('connect', () => {
    console.log('connection');
    _connected = true;
  });
  _simplePeer.on('close', () => (_connected = false));
  console.log(instanceCreatedCallbacks);
  instanceCreatedCallbacks.forEach((callback) => callback());
};

const setGameId = (gameId: GameIdType) => {
  _gameId = gameId;
  setGameIdCallbacks.forEach((callback) => callback(_gameId));
};

const createInstance = (initiator: boolean) => {
  _simplePeer = new SimplePeer({ initiator, trickle: false });
  setListeners();
};

const gameCreated = () =>
  gameCreatedCallbacks.forEach((callback) => callback(_gameId));

export const newGame = (): GameIdType => {
  createInstance(true);

  const gameId = createGameId();
  setGameId(gameId);

  _simplePeer.on('signal', async (offer: SignalData) => {
    await createGameOffer(gameId, offer);

    gameCreated();

    const unwatch = watchGameConnection(gameId, (gameConnection) => {
      if (!isAnwser(gameConnection)) {
        return;
      }
      _simplePeer.signal(gameConnection.answer);
      removeGameConnection(gameId);
      unwatch();
    });
  });

  return gameId;
};

export const joinGame = async (gameId: GameIdType) => {
  console.log('joinGame', gameId);
  createInstance(false);

  setGameId(gameId);

  _simplePeer.on('signal', (anwser: SignalData) => {
    setGameAnwser(gameId, anwser);
  });

  const offer = await getGameOffer(gameId);
  console.log('fetched offer', offer);
  _simplePeer.signal(offer);
};

export const sendData = (data: DataType) => {
  _simplePeer.send(JSON.stringify(data));
};

export const onReceived = (callback: DataReceivedCallback) => {
  _simplePeer.on('data', (stringifiedData: SimplePeer.SimplePeerData) => {
    const data = JSON.parse(stringifiedData.toString());
    callback(data);
  });
};

export const onGameCreated = (callback: GameCreatedCallback) =>
  gameCreatedCallbacks.push(callback);

export const onConnectionChange = (callback: ConnectionCallback) => {
  _simplePeer.on('connect', () => callback(true));
  _simplePeer.on('close', () => callback(false));
};

export const getConnected = () => _connected;

export const onSetGameId = (callback: SetGameIdCallback) =>
  setGameIdCallbacks.push(callback);

export const onInstanceCreated = (callback: InstanceCreatedCallback) => {
  instanceCreatedCallbacks.push(callback);
  if (_simplePeer) {
    callback();
  }
};

export const getGameId = (): GameIdType => _gameId;

export type DataType = Record<string, any>; // eslint-disable-line @typescript-eslint/no-explicit-any
type DataReceivedCallback = (data: DataType) => void;
type GameCreatedCallback = (gameId: GameIdType) => void;
type ConnectionCallback = (connected: boolean) => void;
type SetGameIdCallback = (gameId: GameIdType) => void;
export type InstanceCreatedCallback = () => void;
