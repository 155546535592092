import { useState, useEffect, useCallback } from 'react';
import { useMovesState, setMoveConquered } from '~state/moves';
import { useUsersState } from '~state/users';
import { sendMoveConquered } from '~services/message';
import { useGameState } from '~state/game';

const useBoxState = (x: number, y: number) => {
  const [boxState, setBoxState] = useState<BoxStateType>({
    top: false,
    right: false,
    bottom: false,
    left: false,
  });
  const [moveCounter, setMoveCounter] = useState(0);
  const [hasLastMove, setHasLastMove] = useState(false);

  const [moves] = useMovesState();

  const { users, currentUserId } = useUsersState();
  const [, setGameState] = useGameState();

  const boxConquered =
    boxState.top && boxState.bottom && boxState.left && boxState.right;

  const playerConquered =
    boxConquered && boxState.lastPlayer !== undefined
      ? users.get(boxState.lastPlayer) ?? null
      : null;

  const processMove = useCallback(
    (move: MoveType) => {
      const isTop =
        move.x === x && move.y === y && move.direction === 'horizontal';
      const isLeft =
        move.x === x && move.y === y && move.direction === 'vertical';
      const isBottom =
        move.x === x && move.y === y + 1 && move.direction === 'horizontal';
      const isRight =
        move.x === x + 1 && move.y === y && move.direction === 'vertical';

      if (isTop) {
        setBoxState((prevState) => ({
          ...prevState,
          top: true,
          lastPlayer: move.playerId,
        }));
      }
      if (isBottom) {
        setBoxState((prevState) => ({
          ...prevState,
          bottom: true,
          lastPlayer: move.playerId,
        }));
      }
      if (isLeft) {
        setBoxState((prevState) => ({
          ...prevState,
          left: true,
          lastPlayer: move.playerId,
        }));
      }
      if (isRight) {
        setBoxState((prevState) => ({
          ...prevState,
          right: true,
          lastPlayer: move.playerId,
        }));
      }
      return isTop || isBottom || isLeft || isRight;
    },
    [x, y]
  );

  useEffect(() => {
    if (!moves.length || boxConquered) {
      return;
    }
    let boxHasLastMove = false;
    for (let i = moveCounter; i < moves.length; i++) {
      boxHasLastMove = processMove(moves[i]);
    }
    setHasLastMove(boxHasLastMove);
    setMoveCounter(moves.length);
  }, [moves]);

  useEffect(() => {
    const lastMoveIndex = moveCounter - 1;
    const lastMove = moves[lastMoveIndex];
    if (
      hasLastMove &&
      boxConquered &&
      lastMove.playerId === currentUserId &&
      !lastMove.playerConquered
    ) {
      setMoveConquered(lastMoveIndex);
      sendMoveConquered(lastMoveIndex);
    }
  }, [hasLastMove, boxConquered]);

  useEffect(() => {
    if (boxConquered) {
      setGameState((previousState) => {
        const score = [...previousState.score];
        const playerIndex = previousState.players.findIndex(
          (playerId) => playerId === boxState.lastPlayer
        );
        score[playerIndex] = (score[playerIndex] ?? 0) + 1;
        return { ...previousState, score };
      });
    }
  }, [boxConquered, boxState]);

  return { boxConquered, playerConquered };
};

export default useBoxState;
