import { firestore } from '~services/firebase';

const watchGameConnection = (
  gameId: GameIdType,
  callback: WatchGameConnectionCallback
) =>
  firestore
    .collection('games')
    .doc(gameId)
    .onSnapshot((snapshot) => {
      const connectionData = snapshot.data() as GameConnectionType;
      callback(connectionData);
    });

export default watchGameConnection;

type WatchGameConnectionCallback = (gameConnection: GameConnectionType) => void;
