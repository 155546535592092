import React, { useEffect } from 'react';
import bemClassNames from '@enrise/bemclassnames';
import PlayingField from '~components/PlayingField';
import './Home.scss';

import GameHeader from '~components/GameHeader';
import useGameScore from '~hooks/useGameScore';
import { useHistory } from 'react-router-dom';
import { GAME_OVER } from '~routing';

const cn = bemClassNames('PageHome');

const Home = () => {
  const { gameOver } = useGameScore();
  const history = useHistory();

  useEffect(() => {
    if (gameOver) {
      history.push(GAME_OVER);
    }
  }, [gameOver]);

  return (
    <main className={cn()}>
      <GameHeader />
      <div className={cn('playingFieldContainer')}>
        <PlayingField />
      </div>
      {gameOver && 'GAME OVER'}
    </main>
  );
};

export default Home;
