import React, { useState } from 'react';
import { CirclePicker } from 'react-color';
import InputNumber, { InputNumberProps } from 'antd/lib/input-number';
import Form, { Rule } from 'antd/lib/form';
import { Store } from 'antd/lib/form/interface';
import { useGameState } from '~state/game';
import bemClassNames from '@enrise/bemclassnames';
import { useUsersState } from '~state/users';
import { nanoid } from 'nanoid';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';

const numberInputDefaults: InputNumberProps = {
  min: 3,
  max: 20,
};

const nameRules: Rule[] = [
  {
    required: true,
    whitespace: true,
    message:
      "How can we congratulate you when you win when we don't know your name?",
  },
];

const cn = bemClassNames('HostGame');

const sizeRules: Rule[] = [{ required: true, message: 'Just pick a number' }];

type GameSettingsProps = {
  onStepFinish: () => void;
};

const GameSettings = ({
  onStepFinish: handleStepFinish,
}: GameSettingsProps) => {
  const [color, setColor] = useState('#f44336');

  const [, setGame] = useGameState();

  const { addUser, setCurrentUser } = useUsersState();

  const finishHandle = (values: Store) => {
    const userId = nanoid(6);
    const newGame: GameType = {
      width: values.width,
      height: values.height,
      players: [userId],
      currentPlayerId: userId,
      score: [0],
    };
    addUser(userId, { name: values.name, color });
    setCurrentUser(userId);
    setGame(newGame);
    handleStepFinish();
  };

  return (
    <Form
      onFinish={finishHandle}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 10 }}
    >
      <Form.Item label="Your name" name="name" rules={nameRules}>
        <Input></Input>
      </Form.Item>
      <Form.Item label="Pick your color">
        <CirclePicker
          color={color}
          onChangeComplete={(color) => setColor(color.hex)}
        />
      </Form.Item>
      <Form.Item label="Choose a board size">
        <Input.Group compact>
          <Form.Item name="width" rules={sizeRules} initialValue={6}>
            <InputNumber {...numberInputDefaults} placeholder="width" />
          </Form.Item>
          <Form.Item name="height" rules={sizeRules} initialValue={6}>
            <InputNumber {...numberInputDefaults} placeholder="height" />
          </Form.Item>
          <span className={cn('boardSizeNote')}>(width x height)</span>
        </Input.Group>
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 10 }}>
        <Button htmlType="submit" type="primary">
          Lets connect
        </Button>
      </Form.Item>
    </Form>
  );
};

export default GameSettings;
