import React from 'react';
import bemClassNames from '@enrise/bemclassnames';
import './Box.scss';
import useBoxState from './useBoxState';

const cn = bemClassNames('Box');

type BoxProps = {
  lastInRow?: boolean;
  lastInColumn?: boolean;
  x: number;
  y: number;
};
const Box = ({ lastInRow = false, lastInColumn = false, x, y }: BoxProps) => {
  const { playerConquered, boxConquered } = useBoxState(x, y);

  const color = playerConquered
    ? { background: playerConquered.color }
    : undefined;

  return (
    <div
      className={`${cn()} ${cn({
        '-lastInRow': lastInRow,
        '-lastInColumn': lastInColumn,
        '-conquered': boxConquered,
      })}`.trim()}
      style={color}
    ></div>
  );
};

export default Box;
