import { firestore } from '~services/firebase';

const removeGameConnection = async (gameId: GameIdType) => {
  await firestore
    .collection('games')
    .doc(gameId)
    .delete();
};

export default removeGameConnection;
