import React, { ReactNode } from 'react';
import bemClassNames from '@enrise/bemclassnames';
import './WrapperWithSidebar.scss';
import Layout from 'antd/lib/layout';

const { Sider } = Layout;

const cn = bemClassNames('WrapperWithSidebar');

type WrapperWithSidebarProps = {
  sideBar?: ReactNode;
  children: ReactNode;
};
const WrapperWithSidebar = ({ sideBar, children }: WrapperWithSidebarProps) => (
  <Layout className={cn()}>
    <Layout
      className={cn('mainContent', { 'mainContent--noSidebar': !sideBar })}
    >
      {children}
    </Layout>
    {sideBar && (
      <Sider
        width="30vw"
        breakpoint="lg"
        theme="light"
        collapsedWidth="100vw"
        className={cn('sidebar')}
      >
        {sideBar}
      </Sider>
    )}
  </Layout>
);

export default WrapperWithSidebar;
