import React from 'react';
import DisconnectOutlined from '@ant-design/icons/DisconnectOutlined';
import './CommunicationPane.scss';
import bemClassNames from '@enrise/bemclassnames';
import Chat from '~components/Chat';

const cn = bemClassNames('CommunicationPane');

type CommunicationPaneProps = {
  connected: boolean;
};
const CommunicationPane = ({ connected }: CommunicationPaneProps) => {
  if (!connected) {
    return (
      <div className={cn()}>
        <div className={cn('disconnected')}>
          <div>
            <DisconnectOutlined className={cn('disconnectedIcon')} />
            <div>Disconnected</div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={cn()}>
      <Chat />
    </div>
  );
};

export default CommunicationPane;
