import { sendMessage, onMessageReceived } from '.';
import { onInstanceCreated } from '~services/gameConnection';
import { isAddUserMessage } from './types';
import { addUser } from '~state/users';
import { addPlayer } from '~state/game';
import notificationMessage from 'antd/lib/message';

export const sendAddUser = (userId: UserIdType, user: UserType) =>
  sendMessage({ type: 'addUser', user, userId });

const registerAddUserObserver = () =>
  onMessageReceived((message) => {
    if (!isAddUserMessage(message)) {
      return;
    }
    addUser(message.userId, message.user);
    addPlayer(message.userId);
    notificationMessage.info(`You are connected to ${message.user.name}`);
  });

onInstanceCreated(registerAddUserObserver);
