import React, { useEffect } from 'react';
import bemClassNames from '@enrise/bemclassnames';
import PlayingFieldRow from './PlayingFieldRow';
import './PlayingField.scss';
import { useGameState } from '~state/game';
import { useMovesState } from '~state/moves';

const cn = bemClassNames('PlayingField');

const PlayingField = () => {
  const [{ height: nrOfRows, players }, setGameState] = useGameState();

  const [moves] = useMovesState();

  const getNextPlayerId = (currentPlayerId: UserIdType): UserIdType => {
    const nrOfPlayers = players.length;
    const currentPlayerIndex = players.indexOf(currentPlayerId);
    const nextPlayerIndex =
      currentPlayerIndex + 1 < nrOfPlayers ? currentPlayerIndex + 1 : 0;
    return players[nextPlayerIndex];
  };

  useEffect(() => {
    if (!moves.length) {
      return;
    }
    const lastMove = moves[moves.length - 1];
    const nextPlayerId = lastMove?.playerConquered
      ? lastMove.playerId
      : getNextPlayerId(lastMove.playerId);
    setGameState((previousState) => ({
      ...previousState,
      currentPlayerId: nextPlayerId,
    }));
  }, [moves]);

  const rows = [];
  for (let rowNr = 0; rowNr <= nrOfRows; rowNr++) {
    const lastRow = rowNr === nrOfRows;
    rows.push(<PlayingFieldRow lastRow={lastRow} rowNr={rowNr} key={rowNr} />);
  }

  return <div className={cn()}>{rows}</div>;
};

export default PlayingField;
