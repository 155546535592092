import useConnected from './useConnected';
import { useState, useEffect } from 'react';
import * as gameConnectionService from '~services/gameConnection';

const useGameConnection = () => {
  const [gameId, setGameId] = useState<string>();
  const [gameCreated, setGameCreated] = useState(false);

  const { connected } = useConnected(gameId);

  const joinGame = async (gameId: GameIdType) => {
    await gameConnectionService.joinGame(gameId);
    setGameId(gameId);
  };

  useEffect(() => {
    gameConnectionService.onGameCreated(() => {
      setGameCreated(true);
    });

    gameConnectionService.onSetGameId((gameId: string) => setGameId(gameId));
  }, []);

  const newGame = () => gameConnectionService.newGame();

  return { connected, joinGame, gameId, gameCreated, newGame };
};

export default useGameConnection;
