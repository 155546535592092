import React from 'react';
import JoinGame from '~components/JoinGame';
import PageHeader from 'antd/lib/page-header';
import Layout from 'antd/lib/layout';

const { Content } = Layout;

const JoinGamePage = () => {
  return (
    <>
      <PageHeader
        title="Join a game"
        subTitle="Use the game code you've got from your friend to connect"
        ghost={false}
      />
      <Content>
        <JoinGame />
      </Content>
    </>
  );
};

export default JoinGamePage;
