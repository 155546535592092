import { useGlobalState, setGlobalState } from '..';

export const useGameState = () => useGlobalState('game');

export const addPlayer = (userId: UserIdType) =>
  setGlobalState('game', (currentState) => ({
    ...currentState,
    players: [...currentState.players, userId],
    score: [...currentState.score, 0],
  }));

export const setGameState = (game: GameType) => setGlobalState('game', game);
