import React, { useState, useEffect } from 'react';
import bemClassNames from '@enrise/bemclassnames';
import './Line.scss';
import { useMovesState } from '~state/moves';
import { useGameState } from '~state/game';
import { useUsersState } from '~state/users';
import { sendAddMove } from '~services/message';

const cn = bemClassNames('Line');

type LineProps = {
  direction: MoveType['direction'];
  x: number;
  y: number;
};
const Line = ({ direction, x, y }: LineProps) => {
  const [active, setActive] = useState(false);
  const [activePlayer, setActivePlayer] = useState<UserIdType>();
  const [moves, setMoves] = useMovesState();
  const [{ currentPlayerId }] = useGameState();
  const { users, currentUserId } = useUsersState();

  const myTurn = currentPlayerId === currentUserId;

  const handleMove = () => {
    if (!myTurn || active) {
      return;
    }
    const move = { direction, x, y, playerId: currentPlayerId };
    setMoves((prevMoves) => [...prevMoves, move]);
    sendAddMove(move);
  };

  const isLastMove = () => {
    if (!moves.length) {
      return false;
    }
    const lastMove = moves[moves.length - 1];
    const lastMoveActive =
      lastMove.direction === direction && lastMove.x === x && lastMove.y === y;
    return lastMoveActive;
  };

  useEffect(() => {
    if (!moves.length || active) {
      return;
    }
    if (isLastMove()) {
      const lastMove = moves[moves.length - 1];
      setActive(true);
      setActivePlayer(lastMove.playerId);
    }
  }, [moves]);

  const playerColor = activePlayer && users.get(activePlayer)?.color;
  const shadow =
    (isLastMove() && playerColor && `0 0 12px 2px ${playerColor}`) || undefined;
  const style = { background: playerColor, boxShadow: shadow };

  return (
    <button
      className={`${cn()} ${cn(`-${direction}`, {
        '-active': active,
        '-myTurn': myTurn,
        '-lastMove': isLastMove(),
      })}`.trim()}
      onClick={handleMove}
      style={style}
      disabled={!myTurn}
    ></button>
  );
};

export default Line;
