import { createGlobalState } from 'react-hooks-global-state';
import defaultGame from './game/defaultState';
import defaultMoves from './moves/defaultState';
import defaultUsers from './users/defaultState';

const initialState = {
  game: defaultGame,
  moves: defaultMoves,
  users: defaultUsers,
};
export const {
  useGlobalState,
  getGlobalState,
  setGlobalState,
} = createGlobalState(initialState);
