import React from 'react';
import CreateGame from '~components/CreateGame';
import PageHeader from 'antd/lib/page-header';
import Layout from 'antd/lib/layout';

const { Content } = Layout;

const CreateGamePage = () => (
  <>
    <PageHeader
      title="New game"
      subTitle="Create a new game, connect to a friend and play"
      ghost={false}
    />
    <Content>
      <CreateGame />
    </Content>
  </>
);

export default CreateGamePage;
