import { useEffect, useState } from 'react';
import {
  onMessageReceived,
  getChatMessages,
  sendChatMessage,
  isChatMessage,
} from '~services/message';
import { useUsersState } from '~state/users';

const useChat = () => {
  const [chatMessages, setChatMessages] = useState(getChatMessages());
  const { currentUserId } = useUsersState();

  useEffect(() => {
    onMessageReceived((message) => {
      if (!isChatMessage(message)) {
        return;
      }
      setChatMessages((previousChatMessages) => [
        ...previousChatMessages,
        message,
      ]);
    });
  }, []);

  const sendMessage = (message: string) => {
    if (!currentUserId) {
      return;
    }
    sendChatMessage(message, currentUserId);
  };

  return { chatMessages, sendChatMessage: sendMessage };
};

export default useChat;
