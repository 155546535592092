import {
  onInstanceCreated,
  onReceived,
  sendData,
} from '~services/gameConnection';
import { isMessage, MessageType } from './types';

const messages: MessageType[] = [];
const messageReceivedCallbacks: MessageReceivedCallback[] = [];

export const addMessage = (message: MessageType) => {
  messages.push(message);
  messageReceivedCallbacks.forEach((callback) => callback(message));
};

const registerMessageObserver = () => {
  onReceived((message) => {
    if (isMessage(message)) {
      console.log('received', message);
      addMessage(message);
    }
  });
};

export const sendMessage = (message: MessageType) => {
  console.log('send', message);
  sendData(message);
};

export const getMessages = () => messages;

export const onMessageReceived = (callback: MessageReceivedCallback) =>
  messageReceivedCallbacks.push(callback);

onInstanceCreated(registerMessageObserver);

type MessageReceivedCallback = (message: MessageType) => void;
