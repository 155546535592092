import { useEffect, useState } from 'react';
import { onConnectionChange, getConnected } from '~services/gameConnection';

const useConnected = (gameId?: GameIdType) => {
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    setConnected(getConnected());
    if (!gameId) {
      return;
    }
    onConnectionChange((connectionState) => setConnected(connectionState));
  }, [gameId]);

  return { connected };
};

export default useConnected;
