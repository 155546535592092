import React, { useState } from 'react';
import { CirclePicker } from 'react-color';
import { Store } from 'antd/lib/form/interface';
import { characters } from '~helpers/createGameId';
import useGameConnection from '~hooks/useGameConnection';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import bemClassNames from '@enrise/bemclassnames';
import { useParams } from 'react-router-dom';
import { useUsersState } from '~state/users';
import { nanoid } from 'nanoid';
import Input from 'antd/lib/input';
import Form, { Rule } from 'antd/lib/form';
import Button from 'antd/lib/button';

const nameRules: Rule[] = [
  {
    required: true,
    whitespace: true,
    message:
      "How can we congratulate you when you win when we don't know your name?",
  },
];

const gameCodeRules: Rule[] = [
  {
    required: true,
    min: 5,
    max: 5,
    message: 'A game code is 5 characters long',
  },
  {
    pattern: new RegExp(`^[${characters}]*$`),
    message: `A game code can only contain the following characters: ${characters}`,
  },
];

const cn = bemClassNames('JoinGame');

const GameSettings = () => {
  const [color, setColor] = useState('#2196f3');
  const [connecting, setConnecting] = useState(false);
  const { gameId } = useParams();

  const { addUser, setCurrentUser } = useUsersState();

  const { joinGame } = useGameConnection();

  const finishHandle = (values: Store) => {
    const userId = nanoid(6);
    const player: UserType = { color, name: values.name };

    addUser(userId, player);
    setCurrentUser(userId);

    joinGame(values.gameCode);
    setConnecting(true);
  };

  if (connecting) {
    return (
      <div className={cn('connectionLoading')}>
        <div className={cn('connectionLoadingMessage')}>Connecting</div>
        <div className={cn('connectionLoadingIcon')}>
          <LoadingOutlined />
        </div>
      </div>
    );
  }
  return (
    <Form
      onFinish={finishHandle}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 10 }}
    >
      <Form.Item
        label="Game code"
        name="gameCode"
        rules={gameCodeRules}
        initialValue={gameId}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Your name" name="name" rules={nameRules}>
        <Input />
      </Form.Item>
      <Form.Item label="Pick your color">
        <CirclePicker
          color={color}
          onChangeComplete={(color) => setColor(color.hex)}
        />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 10 }}>
        <Button htmlType="submit" type="primary">
          Join game
        </Button>
      </Form.Item>
    </Form>
  );
};

export default GameSettings;
