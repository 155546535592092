import { timeStamp, firestore } from '~services/firebase/firebase';

const setGameAnwser = async (gameId: GameIdType, answer: SignalData) => {
  const connectionData: GameConnectionAnswerType = {
    answer,
    answerCreatedOn: timeStamp.now(),
  };
  await firestore
    .collection('games')
    .doc(gameId)
    .set(connectionData);
};

export default setGameAnwser;
