import { firestore, timeStamp } from '../firebase';

const createGameOffer = async (gameId: GameIdType, offer: SignalData) => {
  const connectionData: GameConnectionOfferType = {
    offer,
    offerCreatedOn: timeStamp.now(),
  };
  await firestore
    .collection('games')
    .doc(gameId)
    .set(connectionData);
};

export default createGameOffer;
