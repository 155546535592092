import { useGlobalState, setGlobalState } from '..';

export const useMovesState = () => useGlobalState('moves');

export const addMove = (move: MoveType) =>
  setGlobalState('moves', (previousState) => [...previousState, move]);

export const setMoveConquered = (index: number) =>
  setGlobalState('moves', (previousState) => {
    const move: MoveType = { ...previousState[index], playerConquered: true };

    const newState = [...previousState];
    newState.splice(index, 1, move);
    return newState;
  });
