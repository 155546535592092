export const CREATE_GAME = '/create-game';
export const JOIN_GAME = '/join/:gameId?';
export const HOME = '/';
export const GAME_OVER = '/game-over';

export const getUrl = (url: string, params: Record<string, string>) => {
  let newUrl = url;
  for (const key in params) {
    newUrl = newUrl.replace(new RegExp(`:${key}\\??`, 'g'), params[key]);
  }
  return newUrl;
};
