import { firestore } from '../firebase';

const getGameOffer = async (gameId: GameIdType) => {
  const snapshot = await firestore
    .collection('games')
    .doc(gameId)
    .get();
  const connectionData = snapshot.data() as GameConnectionOfferType;
  return connectionData.offer;
};

export default getGameOffer;
