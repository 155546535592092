import { sendMessage, onMessageReceived } from '.';
import { onInstanceCreated } from '~services/gameConnection';
import {
  isNewGameMessage,
  isAddMoveMessage,
  isSetMoveConqueredMessage,
} from './types';
import { getUsers } from '~state/users';
import { setGameState } from '~state/game';
import { addMove, setMoveConquered } from '~state/moves';
import notificationMessage from 'antd/lib/message';

export const sendNewGame = (game: GameType) =>
  sendMessage({ type: 'newGame', game });

export const sendAddMove = (move: MoveType) =>
  sendMessage({ type: 'addMove', move });

export const sendMoveConquered = (moveIndex: number) =>
  sendMessage({ type: 'setMoveConquered', moveIndex });

const registerNewGameObserver = () =>
  onMessageReceived((message) => {
    if (!isNewGameMessage(message)) {
      return;
    }
    const users = [...getUsers().keys()];
    setGameState({
      ...message.game,
      players: users,
      score: users.map(() => 0),
    });
    notificationMessage.info(`New game initiated`);
  });

const registerAddMoveObserver = () => {
  onMessageReceived((message) => {
    if (!isAddMoveMessage(message)) {
      return;
    }
    addMove(message.move);
  });
};

const registerSetMoveConqueredObserver = () => {
  onMessageReceived((message) => {
    if (!isSetMoveConqueredMessage(message)) {
      return;
    }
    setMoveConquered(message.moveIndex);
  });
};

onInstanceCreated(registerNewGameObserver);
onInstanceCreated(registerAddMoveObserver);
onInstanceCreated(registerSetMoveConqueredObserver);
